import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Hero from '../components/hero'


const NotFoundPage = ({data}) => (
  <Layout>
    <Helmet
      title="Pagina non trovata"
      bodyAttributes={
        { class :'has-hero'}
      }
    />
    <Hero data={{
      "title": "Ooops... <br/> la pagina che stai cercando non si trova, forse l'hanno nascosta loro",
      "subtitle": "",
      "img": data.hero.childImageSharp.fluid,
      "arealink": false 
    }}/>
    <div className="container">
      <div className="section section--m-top">
        <div className="row">
          <div className="col-12 text-center">
            <h2>Nel frattempo puoi:</h2>
            <p>
              <div><Link to="/">Tornare alla home</Link></div>
              <div><Link to="/servizi/">Guardare i nostri servizi</Link></div>
              <div><Link to="/staff/">Conoscere il nostro staff</Link></div>
              <div><Link to="/dove-siamo/">Venirci a trovare</Link></div>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage;

export const query = graphql`
query NotFoundPageQuery{
  hero: file(relativePath: {eq: "404.png"}) {
    childImageSharp{
      fluid(maxWidth: 1920){
        ...GatsbyImageSharpFluid_withWebp
        src
      }
    }
  }
}
`
